<template>
  <div class="member-row">
    <CustomCheckbox
      v-if="isCheckboxShown"
      :isCheckboxChecked="isCheckboxChecked"
      @click="handleCheckboxClick"
    />
    <div class="member-row-text-field" :title="name">
      {{ name }}
    </div>
    <div
      class="member-row-text-field"
      :title="
        expirationDate ? monthConst + '/' + dayConst + '/' + yearConst : 'None'
      "
    >
      {{
        expirationDate ? monthConst + '/' + dayConst + '/' + yearConst : 'None'
      }}
    </div>
    <div
      class="member-row-text-field"
      :title="Object.keys(UserSubscriptionStatus)[status]"
    >
      {{ Object.keys(UserSubscriptionStatus)[status] }}
    </div>
    <div class="member-row-text-field" :title="lastLogin">
      {{ lastLogin }}
    </div>
    <div
      class="member-row-text-field"
      :title="
        role === 8
          ? 'Sales Manager'
          : role === 7
          ? 'Business Owner'
          : 'Regular User'
      "
    >
      {{
        role === 8
          ? 'Sales Manager'
          : role === 7
          ? 'Business Owner'
          : 'Regular User'
      }}
    </div>
    <div class="member-row-text-field" :title="company">
      {{ company }}
    </div>
  </div>
</template>

<script>
import CustomCheckbox from '../../common/CustomCheckbox.vue'
import MoreActionsBtn from '../../common/MoreActionsBtn.vue'
import ActionMenu from '../../common/actionMenu/ActionMenu.vue'
import ActionMenuItem from '../../common/actionMenu/ActionMenuItem.vue'

import { UserSubscriptionStatus } from '../../../utils/constants'
import { convertUTCDateTimeToLocal } from '../../../utils/helpers'

export default {
  props: [
    'id',
    'selectedRows',
    'name',
    'expirationDate',
    'status',
    'lastLoginDate',
    'company',
    'menuActions',
    'isCheckboxShown',
    'actionMenuWidth',
    'actionMenuHeight',
    'actionMenuTop',
    'role',
  ],
  components: {
    CustomCheckbox,
    MoreActionsBtn,
    ActionMenu,
    ActionMenuItem,
  },
  data() {
    return {
      isCheckboxChecked: false,
      isActionMenuShown: false,
      UserSubscriptionStatus: UserSubscriptionStatus,
    }
  },
  created() {
    this.isCheckboxChecked = this.selectedRows.includes(this.id)
  },
  watch: {
    selectedRows(newValue, _) {
      if (newValue.includes(this.id)) this.isCheckboxChecked = true
      else this.isCheckboxChecked = false
    },
  },
  computed: {
    dayConst() {
      return ('0' + new Date(this.expirationDate * 1000).getUTCDate()).slice(-2)
    },
    monthConst() {
      return (
        '0' +
        (new Date(this.expirationDate * 1000).getUTCMonth() + 1)
      ).slice(-2)
    },
    yearConst() {
      return new Date(this.expirationDate * 1000).getFullYear()
    },
    displayedMenuActions() {
      const displayedActions = [
        this.menuActions.EDIT_MEMBER,
        this.menuActions.LOOK_IN,
        this.menuActions.DELETE,
      ]

      if (
        this.status === this.UserSubscriptionStatus.NotStarted ||
        this.status === this.UserSubscriptionStatus.Stopped
      ) {
        displayedActions.unshift(this.menuActions.GRANT_SUBSCRIPTION)
      } else if (
        this.status === this.UserSubscriptionStatus.Granted ||
        this.status === this.UserSubscriptionStatus['Granted Until']
      ) {
        displayedActions.unshift(this.menuActions.EDIT_SUBSCRIPTION)
        displayedActions.push(this.menuActions.STOP_SUBSCRIPTION)
      }

      if (this.role === 8) {
        displayedActions.push(this.menuActions.UNASSIGN_SALES_ROLE)
        displayedActions.push(this.menuActions.MANAGE_USERS)
      } else {
        displayedActions.push(this.menuActions.ASSIGN_SALES_ROLE)
      }

      return displayedActions
    },
    lastLogin() {
      return this.convertUTCDateTimeToLocal(this.lastLoginDate)
    },
  },
  methods: {
    convertUTCDateTimeToLocal,
    handleCheckboxClick() {
      if (this.isCheckboxChecked) {
        this.isCheckboxChecked = false
        this.$emit('removeSelection')
      } else {
        this.isCheckboxChecked = true
        this.$emit('checkboxSelect')
      }
    },
    switchActionMenuState() {
      if (this.role === 7) return
      if (this.isActionMenuShown) this.isActionMenuShown = false
      else this.isActionMenuShown = true
    },
    closeActionMenu() {
      this.isActionMenuShown = false
    },
    handleActionMenuItemClick(action) {
      this.isActionMenuShown = false
      this.$emit('actionMenuItemClick', action)
    },
  },
}
</script>

<style lang="scss">
@import '../../../assets/styleVars.scss';

.member-row {
  width: calc(100% - 80px);
  height: 44px;
  padding: 0 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: $fontSizeStandart;
  color: $fontColorBlack;
  position: relative;

  .member-row-text-field {
    width: 150px;
    min-width: 150px;
    height: 20px;
    margin-left: 30px;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;
  }

  .action-btn-absolute-wrapper {
    position: absolute;
    top: 5px;
    right: 0;
    padding-right: 40px;
    background-color: white;
  }

  .action-btn-container {
    width: 42px;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;

    .more-actions-btn {
      border: none;
    }

    .more-actions-btn-active-state {
      border: 1px solid $grayBorderColor;
    }
  }
}
</style>
