<template>
  <div class="member-row">
    <div class="member-row-text-field">
      <span :title="name">{{ name }}</span>
    </div>
    <div class="member-row-text-field">
      {{
        expirationDate ? monthConst + '/' + dayConst + '/' + yearConst : 'None'
      }}
    </div>
    <div class="member-row-text-field">
      {{ lastLogin }}
    </div>
    <div class="member-row-text-field">
      {{ formatPipelineValue(pipelineValue) }}
    </div>
    <div class="member-row-text-field">
      {{
        monthlyRevenueGoal && monthlyRevenueGoal.toString().length > 12
          ? '$' +
            monthlyRevenueGoal
              .substring(0, 12)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
            '...'
          : monthlyRevenueGoal
          ? '$' +
            monthlyRevenueGoal?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          : '-'
      }}
    </div>
    <div class="member-row-text-field">
      {{ confidenceLevel ? confidenceLevel + '%' : '-' }}
    </div>
    <div class="member-row-text-field">
      <span :title="nonRevenueGoal ? nonRevenueGoal : '-'">{{
        nonRevenueGoal ? nonRevenueGoal : '-'
      }}</span>
    </div>
  </div>
</template>

<script>
import MoreActionsBtn from '../common/MoreActionsBtn.vue'
import ActionMenu from '../common/actionMenu/ActionMenu.vue'
import ActionMenuItem from '../common/actionMenu/ActionMenuItem.vue'

import { UserSubscriptionStatus } from '../../utils/constants'
import { convertUTCDateTimeToLocal } from '../../utils/helpers'

export default {
  props: [
    'id',
    'name',
    'expirationDate',
    'status',
    'lastLoginDate',
    'menuActions',
    'actionMenuWidth',
    'actionMenuHeight',
    'actionMenuTop',
    'role',
    'monthlyRevenueGoal',
    'confidenceLevel',
    'nonRevenueGoal',
    'pipelineValue',
  ],
  components: {
    MoreActionsBtn,
    ActionMenu,
    ActionMenuItem,
  },
  data() {
    return {
      isActionMenuShown: false,
      UserSubscriptionStatus: UserSubscriptionStatus,
    }
  },
  computed: {
    dayConst() {
      return ('0' + new Date(this.expirationDate * 1000).getUTCDate()).slice(-2)
    },
    monthConst() {
      return (
        '0' +
        (new Date(this.expirationDate * 1000).getUTCMonth() + 1)
      ).slice(-2)
    },
    yearConst() {
      return new Date(this.expirationDate * 1000).getFullYear()
    },
    lastLogin() {
      return this.convertUTCDateTimeToLocal(this.lastLoginDate)
    },
  },
  methods: {
    convertUTCDateTimeToLocal,
    switchActionMenuState() {
      if (this.isActionMenuShown) this.isActionMenuShown = false
      else this.isActionMenuShown = true
    },
    closeActionMenu() {
      this.isActionMenuShown = false
    },
    handleActionMenuItemClick(action) {
      this.isActionMenuShown = false
      this.$emit('actionMenuItemClick', action)
    },
    formatPipelineValue(value) {
      if (value && value > 0) {
        return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }

      return '$0'
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/styleVars.scss';

.member-row {
  width: calc(100% - 80px);
  height: 44px;
  padding: 0 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: $fontSizeStandart;
  color: $fontColorBlack;
  position: relative;

  .member-row-text-field {
    width: 150px;
    max-width: 150px;
    height: 20px;
    margin-left: 30px;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .action-btn-absolute-wrapper {
    position: absolute;
    top: 5px;
    right: 0;
    padding-right: 40px;
    background-color: white;
  }

  .action-btn-container {
    width: 42px;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;

    .more-actions-btn {
      border: none;
    }

    .more-actions-btn-active-state {
      border: 1px solid $grayBorderColor;
    }
  }
}
</style>
